@import "./../../../assets//styles/base.scss";

.menuItem {
  cursor: pointer;
  display: block;

  .menuItemLink {
    @include font(1.0625rem, 1.25rem, $color-primary, bold, 0);
  }

  &+[class^="menuItem"] {
    margin-top: 30px;
  }
}