@import "./../assets/styles/base.scss";

.toPrevStepButton {
  cursor: pointer;
  height: 44px;
  min-width: 44px;
  padding: 0px 13px 0px 20px;
  border: 0px;
  position: relative;
  z-index: 1;
  background: none;
  @include font(1.125rem, 1, $color-primary, normal, 0);
  animation: fadeIn both 0.4s 0.6s;

  @keyframes fadeIn {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }
}