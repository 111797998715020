@import "./../../../assets/styles/base.scss";

.stepContentWrapper {
    height: 100%;
    overflow: auto;
    padding: 60px 20px 100px 20px;
    max-width: $maxDeviceWidth;
    margin: 0 auto;
}

.helpsBth {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top: 0.5px solid #CACACC;
    z-index: 2;

    [class*="buttonFill"] {
        border-radius: 100px;
    }
  }