.firstTitle {
  font-size: 1.7rem;
  line-height: 2rem;
  color: #1a1a1a;
  font-weight: bold;
  letter-spacing: -0.1px;

  span {
    text-transform: lowercase;
  }

}

.nextStepText {
  position: fixed;
  width: 100%;
  z-index: 1;
  left: 0;
  bottom: 115px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border: none;
    outline: none;
    background: none;
    color: #3E65EF;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.41px;
    padding: 5px;
  }

}