.radioCheckButton_radioCheckButton__1KeRC,
.radioCheckButton_radioCheckButtonChecked__1mQBR {

  .radioCheckButton_resultIconWrapper__3ualD {

    .idealWeight0,
    .idealWeight1,
    .idealWeight2 {
      max-width: 29px;
    }

  }
  
}