@import "./../../../assets/styles/base.scss";

.appStepsWrapper,
.appStepsWrapperHidden,
.appStepsWrapperFinished {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    min-width: 100%;
    z-index: 4;
    background-color: $color-white;

    &.appStepsWrapperFinished{
        position: relative;
        height: auto;
    }

    &.appStepsWrapper {
        @include animation-name(showFrame);
        @include animation-duration($animateStepsSeconds);
        @include animation-iteration-count(1);
        @include animation-fill-mode(forwards);
    }

    &.appStepsWrapperHidden {
        @include animation-name(hideFrame);
        @include animation-duration($animateStepsSeconds);
        @include animation-iteration-count(1);
        @include animation-fill-mode(forwards);
    }

    .container_header {
        // position: relative;
        position: fixed;
        top: 0px;
        left: 0px;

        width: 100%;
        z-index: 2;
    }

    .container_stepContent {
        // position: relative;
        // z-index: 1;
        // height: calc(100vh - 70px);

        position: absolute;
        z-index: 0;
        height: 100%;
        overflow: auto;
        left: 0px;
        top: 0px;
        width: 100%;

        // position: relative;
        // z-index: 1;
        // height: 100vh;
        // overflow: auto;
    }
}

.menuWrapper {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    // height: 60px;
    background-color: $color-white;
    // border-bottom: solid 10px $color-white;
    max-width: $maxDeviceWidth + 40px;
    margin: 0 auto;
    z-index: 5;
    @include box-sizing(content-box);

    .container_menuButton {
        height: 100%;
        padding: 0 20px 0 10px;
        display: flex;
        position: relative;
        z-index: 6;
    }

    .container_menu {
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 0px;
    }
}

.container_stepContent_v2 {
    position: relative;

    [class*='-step_animation'] {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        padding: 0;
      
        &[class*='-IsCurrent'] {
        //   z-index: 2;
          @include animation-name(showFrame);
          @include animation-duration(0.2s);
          @include animation-delay(0.3s);
          @include animation-iteration-count(1);
          @include animation-fill-mode(both);
          @include animation-timing-function(linear);
        }
      
        &[class*='-IsHiding'] {
        //   z-index: 1;
          @include animation-name(hideFrame);
          @include animation-duration(0.2s);
          @include animation-iteration-count(1);
          @include animation-fill-mode(both);
          @include animation-timing-function(linear);
        //   animation: none;
        }
      }
}