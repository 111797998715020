@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?bwk69q');
  src:  url('fonts/icomoon.eot?bwk69q#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?bwk69q') format('truetype'),
    url('fonts/icomoon.woff?bwk69q') format('woff'),
    url('fonts/icomoon.svg?bwk69q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-press:before {
  content: "\e904";
}
.icon-career:before {
  content: "\e908";
}
.icon-feedback:before {
  content: "\e909";
}
.icon-partnership:before {
  content: "\e90a";
}
.icon-triangle:before {
  content: "\e906";
}
.icon-join_icon_1:before {
  content: "\e903";
}
.icon-join_icon_2:before {
  content: "\e905";
}
.icon-join_icon_3:before {
  content: "\e900";
}
.icon-back:before {
  content: "\e901";
}
.icon-check:before {
  content: "\e902";
}
.icon-users:before {
  content: "\e907";
}
