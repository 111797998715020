@import "./../../assets/styles/base.scss";

.faq {
    padding: 20px;

    .logo {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 54px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 82px;
        }
    }

    .faqData {
        margin-top: 10px;

        .contactItem {
            padding: 20px 0px;

            .title {
                @include font(1.5rem, 1, $color4, normal, null);
            }

            .container_contentWrapper {
                margin-top: 10px;
                font-family: $font2;

                [class*='paragraph'] {
                    font-weight: 300;
                }

                a {
                    color: $color-primary;
                }
            }
        }
    }
}