@import "./../../../assets/styles/base.scss";

.radioButton,
.radioButtonChecked {
  cursor: pointer;
  padding: 17px 20px;
  border: 0px;
  position: relative;
  z-index: 1;
  background: none;
  text-align: left;
  width: 100%;
  max-width: $maxDeviceWidth;
  @include font(1.0625rem, 1.25rem, $color4, bold, 0);
  @include border-radius(5px);
  @include transition(color $animateElSeconds);
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(270deg, rgba(255, 102, 140, 0.98) 0%, rgba(245, 29, 71, 0.98) 100%);
    @include transition(opacity $animateElSeconds);
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -2;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 1;
    border: 1px solid #D8D8D8;
    border-radius: 16px;
    @include transition(opacity $animateElSeconds);
  }

  &.radioButtonChecked {
    color: $color-white;

    &:after {
      opacity: 1;
    }

    &:before {
      opacity: 0;
    }
  }

  & + [class^="radioButton"]{
    margin-top: 10px;
  }
}