@import "./../../../assets/styles/base.scss";

.buttonText {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 4px;
    width: 74%;
}

.description {
  margin-top: 10px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: -0.08px;
  color: #808080;
}

.image {
    height: 100%;
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
}
