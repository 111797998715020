@import "./../../../assets/styles/base.scss";

.imgAchived {
  position: absolute;
  bottom: 90px;
  width: 100%;
  object-fit: cover;
  max-width: 428px;
  left: 50%;
  transform: translate(-50%, 0);

  @media (width: 320px) {
    height: 198px;
  }

  @media (width: 375px) {
    height: 232px;
  }

  @media (width: 390px) {
    height: 241.3px;
  }

  @media (width: 414px) {
    height: 256.1px;
  }

  @media (min-width: 428px) {
    height: 264.8px;
  }
  
}

.achievedProgress {

  &_button_wrapper {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top: 0.5px solid #cacacc;
    z-index: 2;
  }

  &_button {
    position: relative;
    border-radius: 100px;
    cursor: pointer;
    padding: 0px 24px;
    border: 0px;
    position: relative;
    z-index: 1;
    text-align: left;
    max-width: $maxDeviceWidth;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    background: #D8D8D8;
    @include font(1.5rem, 3.125rem, $color-yellow, normal, 0);
    @include transition(opacity $animateElSeconds);
    font-weight: 500;
    overflow: hidden;
  }

  &_button_overlay {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(270deg, rgba(255, 102, 140, 0.98) 0%, rgba(245, 29, 71, 0.98) 100%);;
    z-index: -1;
    will-change: transform;
    transform-origin: left top;
    transition: transform .1s linear;
  }

  &_button_text {
    animation: fadeIn 0.2s 0.2s linear both;
  }

}