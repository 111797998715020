@import "./../../../assets/styles/base.scss";

.stepContentWrapper {
  height: 100%;
  overflow: auto;
  padding: 58px 20px 100px 20px;
  max-width: $maxDeviceWidth;
  margin: 0 auto;
  color: #fff;
}

.visibleResultProgress {
  &_title {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.41px;
    color: #030303;
  }

  &_card {
    margin-top: 16px;
    width: 100%;
    height: auto;
  }

  &_card_image {
    width: 100%;
    height: auto;
    border-radius: 16px;
    overflow: hidden;

    @media (width: 320px) {
      height: 195.5px;
    }

    @media (width: 375px) {
      height: 234px;
    }

    @media (width: 390px) {
      height: 244.5px;
    }

    @media (width: 414px) {
      height: 261.2px;
    }

    @media (min-width: 428px) {
      height: 271px;
    }
    
  }

  &_card_info {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_card_name {
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    color: #030303;
  }

  &_card_rate {
    width: 115px;
    min-width: 115px;
  }

  &_card_text {
    margin-top: 16px;
    font-size: 17px;
    line-height: 22px;
    color: #030303;
  }

  &_button_wrapper {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top: 0.5px solid #cacacc;
    z-index: 2;
  }

  &_button {
    position: relative;
    border-radius: 100px;
    cursor: pointer;
    padding: 0px 24px;
    border: 0px;
    position: relative;
    z-index: 1;
    text-align: left;
    max-width: $maxDeviceWidth;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    background: #D8D8D8;
    // box-shadow: 0px 5px 20px rgba(19, 80, 248, 0.279174);
    @include font(1.5rem, 3.125rem, $color-yellow, normal, 0);
    @include transition(opacity $animateElSeconds);
    font-weight: 500;
    overflow: hidden;
  }

  &_button_overlay {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(270deg, rgba(255, 102, 140, 0.98) 0%, rgba(245, 29, 71, 0.98) 100%);;
    z-index: -1;
    will-change: transform;
    transform-origin: left top;
    transition: transform .1s linear;
  }

  &_button_text {
    animation: fadeIn 0.2s 0.2s linear both;
  }

}
