@import "./../../assets/styles/base.scss";

.stepContentWrapper {
    // background-color: red;
    // height: calc(100vh - 150px);
    // overflow: auto;
    height: 100%;
    overflow: auto;
    padding: 60px 20px 20px 20px;
    // padding-bottom: 170px;
    max-width: $maxDeviceWidth;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    @media (orientation: landscape){
        padding-bottom: 100px;
    }

    &> :last-child {
        margin-bottom: 0px;
    }
}