@import "./../../assets/styles/base.scss";

.contacts {
    padding: 20px;

    .logo {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 54px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 82px;
        }
    }

    .container_firstTitle {}

    .contactsData {
        margin-top: 10px;

        .contactItem {
            display: flex;

            .container_icon {
                width: 64px;
                padding-top: 5px;
                padding-left: 10px;

                .icon {
                    @include font(2rem, 1, $color4, null, null);
                }
            }

            .container_content {
                width: calc(100% - 64px);

                .title {
                    @include font(1.5rem, 1, $color4, 500, null);
                }

                .container_contentWrapper {
                    margin-top: 10px;
                    font-family: $font2;
                    font-weight: 300;

                    [class*='paragraph'] {
                        font-weight: 300;
                    }

                    a {
                        color: $color-primary;
                    }
                }
            }

            padding: 20px 0px;

            &+.contactItem {
                border-top: solid 1px $color3;
            }
        }

    }
}