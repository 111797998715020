@import "./../../../assets/styles/base.scss";

.container_inputLBS {
    margin: 52px auto 0 auto;
    max-width: 134px;
    position: relative;

    &::after {
        content: "lbs";
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 17px;
        line-height: 22px;
        font-weight: bold;
        color: #1a1a1a;
    }
}

.container_inputKG {
    margin: 52px auto 0 auto;
    max-width: 134px;
    position: relative;

    &::after {
        content: "kg";
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 17px;
        line-height: 22px;
        font-weight: bold;
        color: #1a1a1a;
    }
}

.container_validateMessage {
    margin-top: 12px;
    display: none;
    animation: fadeIn 0.2s 0.1s linear both;
}