.SuccessStoryModal {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.SuccessStoryModalBody {
  background: #ffffff;
  border-radius: 8px;
  max-width: 335px;
  max-height: 400px;
  padding: 30px 20px 30px 20px;

  h2 {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.41px;
    color: #030303;
    font-weight: bold;
    text-align: center;
    margin-bottom: 24px;
  }

}

.SuccessStoryModalItems {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button + button {
    margin-top: 11px;
  }

}

.SuccessStoryModalItem {
  width: 100%;
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F8F8F8;
  border-radius: 16px;
  border: none;

  p {
    font-size: 17px;
    line-height: 22px;
    color: #000000;
    font-weight: bold;
  }

}

.SuccessStoryModalItem:active {
  background: linear-gradient(270deg, rgba(255, 102, 140, 0.98) 0%, rgba(245, 29, 71, 0.98) 100%);
  width: 100%;
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: none;

  p {
    font-size: 17px;
    line-height: 22px;
    color: #ffffff;
    font-weight: bold;
  }
}