@import "./../../../assets/styles/base.scss";

.inputNumberWrapper {
  max-width: $maxDeviceWidth;
  margin-top: 25px;

  .input {
    padding: 8px 0px;
    border: solid 0px;
    border-bottom: solid 1px $color3;
    outline: none;
    width: 100%;
    background-color: $color-white !important;
    @include input-placeholder($input-placeholder);
    @include border-radius(0);
    @include box-shadow(none);
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    position: relative;
    --sq-index: 1;
    min-height: 35px;

    &::after {
      position: absolute;
      left: 0;
      color: #aaa;
      content: attr(placeholder);
      width: 100%;
      z-index: var(--sq-index);
    }

    input {
      padding: 0px;
      font-family: $font1;
      background-color: $color-white !important;
      width: 100%;
      outline: none;
      @include font(1.625rem, 1.875rem, $color4, 500, 0);
      @include appearance(number-input);
      @include box-shadow(inset 0 0 0 50px $color-white !important);
      @include border-radius(0);
      @include input-placeholder($input-placeholder);
      text-align: center;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        @include box-shadow(inset 0 0 0 50px $color-white !important);
      }

      &::-webkit-calendar-picker-indicator {
        display: none;
      }

      &::-webkit-input-placeholder {
        display: none;
        font-size: 26px;
        line-height: 30px;
        color: #1A1A1A;
        opacity: 0.3;
        font-weight: 500;
      }
    }

    &:focus {
      outline: none;
      background: none;
      border: solid 0px;
      border-right-width: 0px !important;
      border-bottom: solid 1px $color3;
      @include border-radius(0);
      @include box-shadow(none);
      @include input-placeholder($color-white);
      border-right-width: 0px !important;
    }

    &:hover {
      outline: none;
      background: none;
      border: solid 0px;
      border-right-width: 0px !important;
      border-bottom: solid 1px $color3;
      @include border-radius(0);
      @include box-shadow(none);
      border-right-width: 0px !important;
    }

    &::selection {
      border: solid 0px;
      border-right-width: 0px !important;
      border-bottom: solid 1px $color3;
    }

  }

  &::selection,
  [class*='focused'] {
    outline: none;
    border-right-width: 0px !important;
    @include border-radius(0);
    @include box-shadow(none);
    @include input-placeholder($input-placeholder);
  }

  [class*="handler-wrap"] {
    opacity: 0 !important;
    display: none !important;
  }

  [class*="input-wrap"] {
    @include font(inherit, 1, inherit, inherit, inherit);
  }
}