.successStory {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 10;
  overflow: scroll;
  -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.animation {
  width: 170px;
  height: 146px;
  margin: auto auto 0 auto;
  z-index: -1;

  video {
    width: 100%;
  }
}

.title {
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.41px;
  color: #030303;
}

.features {
  position: relative;
  margin-top: 32px;
  width: 100%;
  max-width: 295px;
  margin: 32px auto auto auto;

  &::before {

  }

}

.feature {
  margin-top: 37px;
  height: 34px;
  display: flex;
  align-items: center;
  position: relative;

  &:first-child {
    margin-top: 0;
  }
}

.featureIconWrapper {
  margin-left: 1px;
  position: relative;
}

.featureIcon {
  position: relative;
  margin-right: 12px;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border-radius: 34px;
  background-color: #fff;
  border: 2px solid;
  border-color: #dddddd;
  z-index: 4;
}

.featureIconAnim {
  position: relative;
  margin-right: 12px;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border-radius: 34px;
  background-color: #fff;
  border: 2px solid;
  border-color: #dddddd;
  z-index: 4;
  animation: borderColor 0.2s linear both;
}

// .feature:nth-child(1) .featureIcon {
//   animation-delay: 0;
// }

.feature:nth-child(2) .featureIconAnim {
  animation-delay: 2.8s;
}

.feature:nth-child(3) .featureIconAnim {
  animation-delay: 3s;
}

.feature:nth-child(4) .featureIconAnim {
  animation-delay: 3s;
}

.featureIconImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border-radius: 34px;
  background-image: url("./img/check.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
  opacity: 0;
}

.featureIconImageAnim {
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border-radius: 34px;
  background-image: url("./img/check.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
  opacity: 0;
  animation: fade 0.4s linear both;
  animation-delay: 1.5s;
}

.feature:nth-child(2) .featureIconImageAnim {
  animation-delay: 0s;
}

.feature:nth-child(3) .featureIconImageAnim {
  animation-delay: 0s;
}

.feature:nth-child(4) .featureIconImageAnim {
  animation-delay: 4.5s;
}

.featureIconShadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border-radius: 34px;
  background-color: #EE334B;
  opacity: 0.2;
  z-index: -1;
  transform: scale(1);
}

.featureIconShadowAnim {
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border-radius: 34px;
  background-color: #EE334B;
  opacity: 0.2;
  z-index: -1;
  transform: scale(1);
  animation: pulse 0.6s;
  animation-iteration-count: 3;
}

// .feature:nth-child(1) .featureIconShadow {
//   animation-delay: 0;
// }

.feature:nth-child(2) .featureIconShadowAnim {
  animation-delay: 3s;
}

.feature:nth-child(3) .featureIconShadowAnim {
  animation-delay: 3s;
}

.feature:nth-child(4) .featureIconShadowAnim {
  animation-delay: 3s;
}

.featureText {
  font-size: 17px;
  // color: #030303;
  color: #dddddd;
}

.featureTextAnim {
  font-size: 17px;
  // color: #030303;
  color: #dddddd;
  animation: textColor 0.4s linear both;
}

.feature:nth-child(1) .featureTextAnim {
  animation-delay: 1.5s;
}

// .feature:nth-child(2) .featureText {
//   animation-delay: 4.5s;
// }

// .feature:nth-child(3) .featureText {
//   animation-delay: 7.5s;
// }

.feature:nth-child(4) .featureText {
  animation-delay: 4.5s;
}

.feature1 {
  background-color: red;
}

.stripe {
  position: absolute;
  height: 70px;
  width: 2px;
  border-left: 2px dashed #dddddd;
  position: absolute;
  left: 17px;
  top: 1px;
  z-index: 1;
}

.stripeActiveWrapperAnim {
  height: 0;
  position: absolute;
  left: 17px;
  top: 1px;
  z-index: 2;
  overflow: hidden;
  animation: stripeActiveWrapper 3s linear both;
}

.stripeActiveWrapper {
  height: 0;
  position: absolute;
  left: 17px;
  top: 1px;
  z-index: 2;
  overflow: hidden;
}

.stripeActive {
  height: 70px;
  width: 2px;
  border-left: 2px dashed #EE334B;
}

@keyframes stripeActiveWrapper {
  0% {
    height: 0;
  }

  100% {
    height: 70px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes textColor {
  0% {
    color: #dddddd;
  }

  100% {
    color: #333333;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes borderColor {
  0% {
    border-color: #dddddd;
  }

  100% {
    border-color: #EE334B;
  }
}
