.radioCheckButton_radioCheckButton__1KeRC,
.radioCheckButton_radioCheckButtonChecked__1mQBR {

  .radioCheckButton_resultIconWrapper__3ualD {

    .medications0 {
      max-width: 20px;
    }

    .medications1,
    .medications3 {
      max-width: 28px;
    }

    .medications2,
    .medications4 {
      max-width: 26px;
    }

  }
  
}