.radioCheckButton_radioCheckButton__1KeRC,
.radioCheckButton_radioCheckButtonChecked__1mQBR {

  .radioCheckButton_resultIconWrapper__3ualD {

    .lifestyle0,
    .lifestyle1 {
      max-width: 26px;
    }

  }
  
}

.lifestyleContent {
  margin-top: 16px;
  margin-bottom: 20px;

  p {
    font-size: 17px;
    line-height: 22px;
    color: #030303;
  }

}