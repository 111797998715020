.radioCheckButton_radioCheckButton__1KeRC,
.radioCheckButton_radioCheckButtonChecked__1mQBR {

  .radioCheckButton_resultIconWrapper__3ualD {

    .importantEvent1 {
      max-width: 25px;
    }

    .importantEvent5 {
      max-width: 22px;
    }

  }
  
}