@import "./../../../assets/styles/base.scss";

.inputWrapper {
  max-width: $maxDeviceWidth;
  position: relative;
  height: 44px;

  .input {
    padding: 7px 0px;
    border: solid 0px;
    border: 0.8px solid #EE334B;
    box-sizing: border-box;
    border-radius: 5px;
    outline: none;
    // background-color: $color-white !important;
    
    @include font(17px, 20px, $color4, 500, 0);
    @include input-placeholder(rgba(3, 3, 3, 0.2));
    // @include border-radius(0);
    // @include box-shadow(inset 0 0 0 50px $color-white !important);
    height: 44px;
    letter-spacing: 0.6px;
    padding-left: 20px;
    background: #ffffff;

    // &:-webkit-autofill,
    // &:-webkit-autofill:hover,
    // &:-webkit-autofill:focus,
    // &:-webkit-autofill:active {
    //   @include box-shadow(inset 0 0 0 50px $color-white !important);
    // }

    // &:focus {
    //   outline: none;
    //   background: none;
    //   border: solid 0px;
    //   border-right-width: 0px !important;
    //   border-bottom: solid 1px $color3;
    //   @include border-radius(0);
    //   @include box-shadow(none);
    //   @include input-placeholder($color-white);
    //   border-right-width: 0px;
    // }

    &:hover {
      // outline: none;
      // background: none;
      // border: solid 0px;
      // border-right-width: 0px !important;
      // border-bottom: solid 1px $color3;
      // @include border-radius(0);
      // @include box-shadow(none);
      // border-right-width: 0px;
      background: rgba(228, 233, 249, 0.4);
    }

    // &::selection {
    //   border: solid 0px;
    //   border-bottom: solid 1px $color3;
    // }
  }

  // &::selection {
  //   outline: none;
  //   @include border-radius(0);
  //   @include box-shadow(none);
  //   @include input-placeholder($input-placeholder);
  // }

  .inputLabel {
    position: absolute;
    top: 12px;
    left: 0;
    font-size: 17px;
    line-height: 22px;
    color: #030303;
  }
}