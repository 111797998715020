@import "./../../assets/styles/base.scss";

.termsPolicies {
    padding: 80px 20px 20px;

    .logo {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 54px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 82px;
        }
    }

    .container_termsPolicies {
        margin-top: 30px;
        line-height: 1.5;

        h1 {
            color: #2d2d2d;
            font-size: 22px;
            font-weight: 300
        }

        p {
            margin: 15px 0;
            max-width: 100%;
            word-wrap: break-word
        }

        ul {
            list-style-type: disc;
        }

        ol {
            list-style-type: revert;
        }

        ul,
        ol {
            padding-left: 20px
        }

        li {
            margin: 10px 0
        }

        a {
            color: #2d2d2d;
            display: inline-block;
            max-width: 100%;
            word-wrap: break-word
        }

        table {
            border: 0;
            border-collapse: collapse;
            font-size: 16px
        }

        table th,
        table td {
            border: 1px solid #000;
            padding: 10px 7px
        }
    }
}