@import "./../../../assets/styles/base.scss";

.validateMessage {
  max-width: 335px;
  margin-left: auto;
  margin-right: auto;

  .message {
    text-align: center;
    @include font(13px, 16px, #EE334B, normal, null);
  }
}