@import "./../assets/styles/base.scss";

.radioСhoiceWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F8F8F8;
  // flex-grow: 1;
  @include border-radius(16px);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    // border: 1px #D8D8D8 solid;
    border-radius: 16px;
    pointer-events: none;
  }

  [class*="radioСhoice"] {
    width: 100%;
  }
}