@import "./../../assets/styles/base.scss";

.stepControl {
    position: fixed;
    z-index: 1;
    right: 0;
    // bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // opacity: 0!important;
}