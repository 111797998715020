.relateToContentSurrounding {
  position: absolute;
  left: 0;
  width: 100%;
  height: 309px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background: url("./img/bg.png");
  background-size: cover;
  background-position: bottom;
  max-width: 428px;
  left: 50%;
  transform: translate(-50%, 0);

  p {
    margin-top: 46px;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    color: #030303;
    max-width: 295px;

    span {
      font-weight: bold;
      color: #EE334B;
    }

  }

}

.relateToButtonWrapperSurrounding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  left: 0;
  padding: 0 20px;
  top: 362px;
  max-width: 428px;
  left: 50%;
  transform: translate(-50%, 0);

  [class^="radioCheckButton"] {
    margin-top: 0;
    max-width: 60px;
    min-height: 62px;
    padding: 0;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    line-height: 32px;
    margin-left: 9px;

    &:nth-child(1) {
      margin-left: 0;
    }
  }

  p {
    font-size: 17px;
    line-height: 22px;
    color: #1a1a1a;
    position: absolute;
    bottom: -42px;
  }

  button + p {
    left: 20px;
  }

  p + p {
    right: 20px;
  }
  
}