@import "./../../../assets/styles/base.scss";

.switcher,
.switcherLabel {
  display: flex;
  align-items: center;
  justify-content: start;

  &.switcherLabel {
    justify-content: space-between;
    max-width: $maxDeviceWidth;
  }

  .label {
    display: block;
    padding: 10px 20px 10px 0px;
    @include font(1.0625rem, 1.25rem, $color4, bold, 0);
  }
}