@import "./../../../assets/styles/base.scss";

.progressSteps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;

    .progressItem,
    .progressItemActive {
        height: 3px;
        width: 100%;
        background-color: #E8EAF6;

        &:first-child {
            margin-left: 0px;
        }

        &:last-child {
            margin-right: 0px;
        }

        &.progressItemActive {
            background: #EE334B;
        }
    }
}