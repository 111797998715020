@import "./../../../assets/styles/base.scss";

.radioImageButton,
.radioImageButtonChecked {
  cursor: pointer;
  padding: 0px 125px 0px 20px;
  border: 0px;
  position: relative;
  z-index: 1;
  background: none;
  text-align: left;
  width: 100%;
  height: 105px;
  max-width: $maxDeviceWidth;
  display: flex;
  align-items: center;
  @include font(1.5rem, 1.75rem, $color4, bold, 0);
  @include border-radius(5px);
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    @include transition(opacity $animateElSeconds);
    @include linear-gradientFull(225deg, $linerGrad-color1, 0%, $linerGrad-color2, 100%);
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -2;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: $color2;
    @include transition(opacity $animateElSeconds);
  }

  .itemImage {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 105px;
    height: 100%;
    @include back-img(cover, right center, no-repeat);
  }

  &.radioImageButtonChecked {
    color: $color-white;

    &:after {
      opacity: 1;
    }

    &:before {
      opacity: 0;
    }
  }

  & + [class^="radioImage"]{
    margin-top: 10px;
  }
}