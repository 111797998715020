@import "./../../../assets/styles/base.scss";

.bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 300vw;
  z-index: -1;
  // animation: transformBg 10s linear forwards;
}

.bgPosition2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 300vw;
  z-index: -1;
  animation: transformBg 0.5s linear forwards;;
}

.bgPosition3 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 300vw;
  z-index: -1;
  transform: translate(-100vw);
  animation: transformBg3 0.5s linear forwards;;
}

.container {
  position: relative;
  display: flex;
  width: max-content;
  height: 100%;
  width: 100vw;
  max-width: $maxDeviceWidth;
  overflow: hidden;
  margin: 0 auto;
}

.stepContentWrapper {
  height: 100%;
  overflow: auto;
  padding: 30px 20px 96px 20px;
  width: 100vw;
  max-width: $maxDeviceWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  opacity: 0;
}

.stepContentWrapper1 {
  height: 100%;
  overflow: auto;
  padding: 30px 20px 96px 20px;
  width: 100vw;
  max-width: $maxDeviceWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  animation: animStep1 3.5s linear forwards;
}

.stepContentWrapper2 {
  height: 100%;
  overflow: auto;
  padding: 30px 20px 96px 20px;
  width: 100vw;
  max-width: $maxDeviceWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  opacity: 0;
  animation: animStep2 3s linear forwards;
}

.stepContentWrapper3 {
  height: 100%;
  overflow: auto;
  padding: 30px 20px 96px 20px;
  width: 100vw;
  max-width: $maxDeviceWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  opacity: 0;
  animation: animStep3 0.5s linear forwards;
}


.illust1 {
  // max-width: 187px;
  max-width: 70%;
  margin: 0 auto;
  object-fit: cover;
  z-index: 2;
}

.illust2 {
  // max-width: 282px;
  max-width: 80%;
  margin: 0 auto;
  object-fit: cover;
  z-index: 2;
}

.illust3 {
  // max-width: 273px;
  max-width: 80%;
  margin: 0 auto;
  object-fit: cover;
  z-index: 2;
}

.goalsPlanProgress {

  &_title {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.41px;
    color: #030303;
    font-weight: bold;
    text-align: center;
  }

  &_text {
    font-size: 17px;
    line-height: 22px;
    color: #030303;
    text-align: center;
  }

  &_button_wrapper {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  &_button {
    position: relative;
    border-radius: 100px;
    cursor: pointer;
    padding: 0px 24px;
    border: 0px;
    position: relative;
    z-index: 1;
    text-align: left;
    max-width: $maxDeviceWidth;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    background: #D8D8D8;
    // box-shadow: 0px 5px 20px rgba(19, 80, 248, 0.279174);
    @include font(1.5rem, 3.125rem, $color-yellow, normal, 0);
    @include transition(opacity $animateElSeconds);
    font-weight: 500;
    overflow: hidden;
  }

  &_button_overlay {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(270deg, rgba(255, 102, 140, 0.98) 0%, rgba(245, 29, 71, 0.98) 100%);;
    z-index: -1;
    will-change: transform;
    transform-origin: left top;
    transition: transform .1s linear;
  }

  @keyframes transformBg {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100vw, 0);
    }
  }

  @keyframes transformBg3 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-200vw, 0);
    }
  }

  @keyframes animStep1 {
    0% {
      opacity: 1;
    }
    84% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes animStep2 {
    0% {
      opacity: 0;
    }
    16% {
      opacity: 1;
    }
    84% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes animStep3 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

}