@import "./../../assets/styles/base.scss";

.wrapp {

    .menu,
    .menuOpen {
        position: fixed;
        z-index: 1;
        width: 203px;
        height: 100vh;
        background-color: $color-white;
        opacity: 0;
        $trans: transform $animateElSeconds, opacity $animateElSeconds;
        @include transition($trans);
        @include transform(translateX(-203px));

        .container_menuItems {
            padding-top: 78px;
            padding-left: 20px;
        }

        &.menuOpen {
            opacity: 1;
            @include transform(translateX(0));
        }
    }

    .milkMenuBlock {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    .menuItemDirect {
        cursor: pointer;
        display: block;
        @include font(1.0625rem, 1.25rem, $color-primary, bold, 0);
        margin-top: 30px;
    }

}