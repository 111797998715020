@import "./../../../assets/styles/base.scss";

.headerAppSteps {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 5px;
  height: 44px;
  background-color: $color-white;
  max-width: $maxDeviceWidth + 40px;
  margin: 0 auto;
  z-index: 2;
  @include box-sizing(content-box);
  animation: fadeIn 0.4s 0.4s linear both;

  .container_controls {
    height: 100%;
    min-width: 44px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
  }

  .container_progress {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .container_dots {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
  }

  .container_dot {
    width: 17px;
    height: 17px;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E4E9F9;
    z-index: 3;

    &::after {
      display: none;
      content: "";
      width: 9px;
      height: 6px;
      background-image: url(./img/check.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      top: 1px;
    }
  }

  .container_dot_active {
    background: linear-gradient(270deg, rgba(255, 102, 140, 0.98) 0%, rgba(245, 29, 71, 0.98) 100%);;

    &::after {
      display: block;
    }

    // &:last-child {
    //   &::after {
    //     display: none;
    //   }
    // }
  }

  .container_steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
  }

  .container_step {
    height: 3px;
    width: 100%;
    background-color: #e8eaf6;
    position: relative;
  }

  // .container_step_line {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   height: 3px;
  //   width: 2%;
  //   background: linear-gradient(270deg, rgba(255, 102, 140, 0.98) 0%, rgba(245, 29, 71, 0.98) 100%);;
  //   border-radius: 0 2.5px 2.5px 0;
  //   z-index: 2;
  //   transition: width 0.4s ease;
  // }

  .container_step_line {
    position: absolute;
    left: 0;
    top: 0;
    height: 3px;
    width: 100%;
    background: linear-gradient(270deg, rgba(255, 102, 140, 0.98) 0%, rgba(245, 29, 71, 0.98) 100%);;
    border-radius: 0 2.5px 2.5px 0;
    z-index: 2;
    // transition: width 0.4s ease;

    transform: scaleX(calc(
      ((var(--step) + 1) / var(--totalSteps))
      ));
    transform-origin: 0 0;
    transition: transform 0.4s ease-out;
  }
}
