@import "./../../../assets//styles/base.scss";

.menuButton,
.menuButtonOpen {
  cursor: pointer;
  border: 0px;
  position: relative;
  z-index: 1;
  background: none;
  width: 42px;
  height: 42px;
  left: 10px;
  @include border-radius(0px);

  .line1 {
    display: block;
    position: absolute;
    width: 24px;
    height: 3px;
    top: 10px;
    left: 10px;
    background-color: $color-primary;
    @include border-radius(1.5px);
    @include transform(rotate(0deg));
    $transition: top 0.2s, transform 0.2s;
    @include transition($transition);
  }

  .line2 {
    display: block;
    position: absolute;
    width: 24px;
    height: 3px;
    top: 16px;
    left: 10px;
    background-color: $color-primary;
    opacity: 1;
    @include border-radius(1.5px);
    @include transition(opacity $animateElSeconds);
  }

  .line3 {
    display: block;
    position: absolute;
    width: 24px;
    height: 3px;
    top: 22px;
    left: 10px;
    background-color: $color-primary;
    @include border-radius(1.5px);
    @include transform(rotate(0deg));
    $transition: top 0.2s, transform 0.2s;
    @include transition($transition);
  }

  &.menuButtonOpen {
    // position: fixed;

    .line1 {
      top: calc(50% - 1.5px);
      @include transform(rotate(45deg));
    }

    .line2 {
      opacity: 0;
    }

    .line3 {
      top: calc(50% - 1.5px);
      @include transform(rotate(-45deg));
    }
  }
}