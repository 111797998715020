@import "./../../../assets/styles/base.scss";

.genderWrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
    overflow: hidden;

    button {
        width: calc(50% - 5.5px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        // padding: 20px 16px 60px 16px;
        padding: 30px 16px 83px 16px;
        background-color: #F8F8F8;
        border: none;
        text-align: center;
        margin-top: 0!important;

        img {
            width: auto;
            height: 100%;
            max-height: 339px;
            object-fit: contain;
        }
    }

    .genderText {
        margin-top: 25px;
        height: 20px;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        // color: #1A1A1A;
    }
}

.stepContentWrapper {
    height: 100%;
    overflow: auto;
    padding: 60px 20px 40px 20px;
    max-width: $maxDeviceWidth;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    @media (orientation: landscape){
        padding-bottom: 100px;
    }

    &> :last-child {
        margin-bottom: 0px;
    }
}

.firstTitle {
    // margin-top: 8px;
    margin-bottom: 19px;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.41px
}

.subtitle {
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #7B798F;
}