@import "./../../../assets/styles/base.scss";

.container_validateMessage {
  margin-top: 24px;
  display: none;
  animation: fadeIn 0.2s 0.1s linear both;
}

.title {
  margin-top: 9px;
}

.subtitle {
  font-size: 17px;
  line-height: 22px;
  color: #1a1a1a;
}

.goal {
  color: #6d25a8;
}

.warning {
  margin-top: 49px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: rgba(51, 51, 51, 0.5);
  display: flex;
  align-items: flex-start;
}

.stepContentWrapperMail {
  height: 100%;
  overflow: auto;
  padding: 64px 20px 20px 20px;
  max-width: $maxDeviceWidth;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (orientation: landscape) {
    padding-bottom: 100px;
  }

  & > :last-child {
    margin-bottom: 0px;
  }

  input {
    margin-top: 20px;
  }
}

.logo {
  width: 42px;
  min-width: 42px;
  position: absolute;
  top: 9px;
  left: calc(50% - 21px);
}

.image {
  margin-right: 9px;
}

.emailDescription {
  position: relative;
  top: -2px;
}

.stepContentWrapperMail {
  [class*="ant-input"] {
    height: 54px;
    min-height: 54px;
    border-color: #cccccc;
    background-color: #f8f8f8;

    &:hover {
      border-color: #ee334b;
      outline: none;
    }

    &:focus {
      border-color: #ee334b;
      outline: none;
      box-shadow: none;
    }
  }
}

.mailTitleActivity {
  color: #ee334b;
}
