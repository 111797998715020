$containerLG: 1495px;
$containerSD: 1200px;
$containerMD: 1030px;
$containerSM: 780px;
$containerXS: 100%;
$containerXSS: 100%;

$containerMediumLG: 970px;
$containerMediumSD: 970px;
$containerMediumMD: 100%;
$containerMediumSM: 100%;
$containerMediumXS: 100%;
$containerMediumXSS: 100%;

$containerSmallLG: 830px;
$containerSmallSD: 830px;
$containerSmallMD: 830px;
$containerSmallSM: 730px;
$containerSmallXS: 100%;
$containerSmallXSS: 100%;

$containerXSmallLG: 800px;
$containerXSmallSD: 800px;
$containerXSmallMD: 800px;
$containerXSmallSM: 100%;
$containerXSmallXS: 100%;
$containerXSmallXSS: 100%;

$indent1: 15px;
$indent2: 20px;
$indent3: 30px;
$indent4: 40px;
$indent5: 60px;
$indent6: 10px;
$indentO1: -$indent1;
$indentO2: -$indent2;
$indentO3: -$indent3;
$indentO4: -$indent4;
$indentO5: -$indent5;
$indentO6: -$indent6;

$containerPaddingLG: 0px $indent1;
$containerPaddingSD: 0px $indent1;
$containerPaddingMD: 0px $indent1;
$containerPaddingSM: 0px $indent1;
$containerPaddingXS: 0px $indent2;
$containerPaddingXSS: 0px $indent2;

$rowMarginLG: 0px $indentO3;
$rowMarginSD: 0px $indentO3;
$rowMarginMD: 0px $indentO3;
$rowMarginSM: 0px $indentO3;
$rowMarginXS: 0px $indentO2;
$rowMarginXSS: 0px $indentO2;

$maxMediaSD: 1439px;
$maxMediaMD: 1229px;
$maxMediaSM: 1047px;
$maxMediaXS: 991px;
// $maxMediaXS: 767px;
$maxMediaXSS: 410px;

$minMediaSD: 1440px;
$minMediaMD: 1230px;
$minMediaSM: 992px;
$minMediaXS: 992px;
// $minMediaXS: 768px;
$minMediaXSS: 411px;

$col1: 100% * 1 / 12;
$col2: 100% * 2 / 12;
$col2_4: 100% / 5;
$col3: 100% * 3 / 12;
$col4: 100% * 4 / 12;
$col5: 100% * 5 / 12;
$col6: 100% * 6 / 12;
$col7: 100% * 7 / 12;
$col8: 100% * 8 / 12;
$col9: 100% * 9 / 12;
$col10: 100% * 10 / 12;
$col11: 100% * 11 / 12;
$col12: 100% * 12 / 12;

$baseSize: 16px;

$animateSteps: 500;
$animateEl: 300;
$delayInputTrigger: 100;
$maxDeviceWidth: 428px;

$animateStepsSeconds: $animateSteps / 1000+s;
$animateElSeconds: $animateEl / 1000+s;
$delayInputTriggerSeconds: $delayInputTrigger / 1000+s;

$font1: 'Roboto';
$font2: 'SF Pro Display';
$font3: 'icomoon';

$color-white: #ffffff;
$color-black: #000000;
$color-primary: #EE334B;
$color-yellow: #ffffff;

$checkIcon-color1: #894AC9;
$checkIcon-color2: #605C98;

$linerGrad-color1: #7652B2;
$linerGrad-color2: #605C98;

$buttonGrad-color1: #542285;
$buttonGrad-color2: #222266;

$backGray-color1: #F8F7FC;
$backGray-color2: #ECEBF5;

$input-placeholder: rgba(60, 60, 67, 0.5);

$star-color: #FCBF59;

$checker-back: #D9D9D9;
$checker-stroke: rgba(0, 0, 0, 0.04);

$buble1-color1: #00E67E;
$buble1-color2: #00C1DA;

$buble2-color1: #EE6262;
$buble2-color2: #7726E3;

$buble3-color1: rgba(255, 201, 0, 0.98);
$buble3-color2: rgba(255, 117, 0, 0.98);

$buble4-color1: #4843FF;
$buble4-color2: #7EFFFF;

$buble5-color1: rgba(37, 0, 255, 0.98);
$buble5-color2: rgba(245, 30, 202, 0.98);

$buble6-color1: #4843FF;
$buble6-color2: #7EFFFF;

$graficLeftcell-color1: #FFAE59;
$graficLeftcell-color2: #FF7759;

$graficRightcell-color1: #46FF84;
$graficRightcell-color2: #00B7FF;

$centerCellBack: #F6F6F6;
$centerCellNumber: #737373;
$graficAdditionText: #7D7D7D;

$paramBlock-color1:#F8F7FC;
$paramBlock-color2:#ECEBF5;
$paramsBackGrafic: #DAD8E3;

$bmiBack1:#9CC0E7;
$bmiBack2:#75F4D5;
$bmiBack3:#FFAE59;
$bmiBack4:#FF7759;
$bmiPointerStroke: #ffffff;

$caloriesBack: #75F495;

$waterBack-color1:#6EB0FD;
$waterBack-color2:#0099E1;

$percentBack: #373640;

$color1: #F2F2F2; // gray
$color2: #F3F2F9; // back
$color3: #949494; // dark gray
$color4: #1A1A1A; // black

:export {
  color-white: $color-white;
  color-black: $color-black;
  color-primary: $color-primary;
  checkIcon-color1: $checkIcon-color1;
  checkIcon-color2: $checkIcon-color2;
  linerGrad-color1: $linerGrad-color1;
  linerGrad-color2: $linerGrad-color2;
  buttonGrad-color1: $buttonGrad-color1;
  buttonGrad-color2: $buttonGrad-color2;
  backGray-color1: $backGray-color1;
  backGray-color2: $backGray-color2;
  input-placeholder: $input-placeholder;
  star-color: $star-color;
  checker-back: $checker-back;
  animateEl: $animateEl;
  animateElSeconds: $animateElSeconds;
  animateSteps: $animateSteps;
  animateStepsSeconds: $animateStepsSeconds;
  delayInputTrigger: $delayInputTrigger;
  delayInputTriggerSeconds: $delayInputTriggerSeconds;
}