@import "./../../../assets/styles/base.scss";

.stepContentWrapper {
  height: 100%;
  overflow: auto;
  padding: 30px 0 100px 0;
  max-width: $maxDeviceWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 26px;
  line-height: 32px;
  color: #030303;
  letter-spacing: 0.41px;
  text-align: center;
  margin-bottom: 38px;
  font-weight: bold;
}

.subtitle {
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0.41px;
  font-weight: bold;
  color: #030303;
  text-align: center;
  max-width: 335px;

  span {
    color: #EE334B;
  }

}

.img {
  width: 100%;
  margin-bottom: 10px;

  @media (width: 320px) {
    height: 185.17px;
  }

  @media (width: 375px) {
    height: 217px;
  }

  @media (width: 390px) {
    height: 225.68px;
  }

  @media (width: 414px) {
    height: 239.56px;
  }

  @media (min-width: 428px) {
    height: 247.7px;
  }

}

.goalsPlanButtonWrapper {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top: 0.5px solid #CACACC;
  z-index: 2;
    
  [class*="buttonFill"] {
    border-radius: 100px;
  }

}