@import "./../../../assets/styles/base.scss";

.wrapperResult {
  display: flex;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
}

.stepContentWrapper {
  height: 100%;
  overflow: auto;
  padding: 49px 20px 15px 20px;
  max-width: $maxDeviceWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  background-color: #f4f4f4;
  background-image: url(./img/bg.jpg);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;

  @media (max-height: 680px) {
    padding: 20px 20px 15px 20px;
  } 

  // @media (orientation: landscape) {
  //   padding-bottom: 100px;
  // }

  & > :last-child {
    margin-bottom: 0px;
  }
}

.stepContentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
}

.buttonsWrapper {
  margin-top: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.button {
  width: calc(50% - 7.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  text-align: center;
  border-radius: 16px;

  [class*="radioCheckButton"] {
    min-height: 76px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #ffffff;
    background: linear-gradient(
      270deg,
      rgba(255, 102, 140, 0.98) 0%,
      rgba(245, 29, 71, 0.98) 100%
    );
    box-shadow: 0px 5px 20px rgba(238, 51, 75, 0.4);
    padding: 6px 12px;
    text-align: center;
    justify-content: center;
  }

  [class*="radioCheckButtonChecked"] {
    color: #1a1a1a;
    // background: #F8F8F8;
  }
}

.subtitle {
  margin-top: 39px;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000;

  @media (max-width: 360px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (max-height: 680px) {
    margin-top: 24px;
  } 
}

.title {
  margin-top: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #ee334b;

  @media (max-width: 360px) {
    margin-top: 8px;
    font-size: 18px;
    line-height: 22px;
  }
}

.text {
  margin-top: 24px;
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-align: center;
  color: #808080;

  @media (max-width: 360px) {
    margin-top: 24px;
    font-size: 14px;
    line-height: 16px;
  }
}

.description {
  font-size: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  text-align: center;
  opacity: 0.8;
}

.logoImage {
  width: 42px;
  min-width: 42px;
}

.logoTitle {
  margin-top: 7px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  // letter-spacing: 1px;
  color: #030303;

  @media (max-width: 360px) {
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
  }
}
