.radioCheckButton_radioCheckButton__1KeRC,
.radioCheckButton_radioCheckButtonChecked__1mQBR {

  .radioCheckButton_resultIconWrapper__3ualD {

    .jobActive0 {
      max-width: 28px;
    }

    .jobActive2 {
      max-width: 33px;
    }

  }
  
}
