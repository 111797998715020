@import "./../../../assets/styles/base.scss";

.buttonFill {
  position: relative;
  cursor: pointer;
  padding: 0px 24px;
  border: 0px;
  position: relative;
  z-index: 1;
  text-align: left;
  max-width: $maxDeviceWidth;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  background: linear-gradient(270deg, rgba(255, 102, 140, 0.98) 0%, rgba(245, 29, 71, 0.98) 100%);;
  box-shadow: 0px 5px 20px rgba(238, 51, 75, 0.4);
  border-radius: 0;
  @include font(1.5rem, 3.125rem, $color-yellow, normal, 0);
  @include transition(opacity $animateElSeconds);
  font-weight: 500;

  &:disabled{
    opacity: 0.5;
    // background: url(./img/bg.png);
    // background-repeat: no-repeat;
    // background-size: cover;
  }
}