@import "./../../../assets/styles/base.scss";

.radioСhoice,
.radioСhoiceChecked {
  cursor: pointer;
  padding: 0px;
  border: 0px;
  position: relative;
  z-index: 2;
  background: none;
  text-align: center;
  width: auto;
  min-width: 168px;
  @include font(1.0625rem, 2.5rem, $color4, bold, 0);
  @include border-radius(16px);
  // @include transition(color $animateElSeconds);
  overflow: hidden;

  @media (max-width: 340px) {
      min-width: auto;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    // @include transition(opacity $animateElSeconds);
    background: linear-gradient(270deg, rgba(255, 102, 140, 0.98) 0%, rgba(245, 29, 71, 0.98) 100%);
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -2;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    // opacity: 0;
    // background-color: white;
    @include transition(opacity $animateElSeconds);
  }

  &.radioСhoiceChecked {
    color: $color-white;

    &:after {
      opacity: 1;
    }

    &:before {
      opacity: 0;
    }
  }
}