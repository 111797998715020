@import "./../../../assets/styles/base.scss";

.radioCheckButton,
.radioCheckButtonChecked {
  cursor: pointer;
  padding: 16px 20px 16px 16px;
  border: none;
  position: relative;
  z-index: 1;
  background: #F8F8F8;
  text-align: left;
  width: 100%;
  min-height: 96px;
  max-width: $maxDeviceWidth;
  @include font(1.0625rem, 1.25rem, $color4, bold, 0);
  border-radius: 16px;
  // @include transition(all $animateElSeconds);
  will-change: padding;
  display: flex;
  align-items: center;
  overflow: hidden;

  .container_styleIcon {
    position: absolute;
    top: calc(50% - 17.5px);
    right: 20px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include border-radius(50%);
    @include transition(transform $animateElSeconds);
    @include transform(scale(0));
    background: white;

    [class*="icon"] {
      @include font(0.8125rem, 1, #EE334B, null, null);
    }
  }

  &.radioCheckButtonChecked {
    // padding: 38px 75px 38px 20px;
    // @include transition(all $animateElSeconds);
    // @include font(1.5rem, 1.75rem, null, null, null);
    background: linear-gradient(270deg, rgba(255, 102, 140, 0.98) 0%, rgba(245, 29, 71, 0.98) 100%);
    color: #fff;
    // color: white;
    // border: none;

    // .container_styleIcon {
    //   @include transform(scale(1));

    //   [class*="icon"] {}
    // }

    .resultIconWrapper {
      background: rgba(255, 255, 255, 0.1);
    }

      [class*='description'] {
        color: #fff;
      }
  }
  
  & + [class^="radioCheckButton"]{
    margin-top: 11px;
  }
  
  .resultIconWrapper {
    margin-right: 16px;
    width: 42px;
    height: 42px;
    min-width: 42px;
    min-height: 42px;
    border-radius: 42px;
    background: rgba(228, 233, 249, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
      max-width: 24px;
    }
  }
  
  [class*='result0'] {
    max-width: 28px!important;
  }

  [class*='result1'] {
    max-width: 25.87px!important;
  }

  [class*='result2'] {
    max-width: 29px!important;
  }

  [class*='level0'] {
    max-width: 32px!important;
  }

  [class*='level1'] {
    max-width: 31px!important;
  }

  [class*='level2'] {
    max-width: 29px!important;
  }

  [class*='work0'] {
    max-width: 22px!important;
  }

  [class*='work1'] {
    max-width: 22px!important;
  }

  [class*='work2'] {
    max-width: 22px!important;
  }

  [class*='work3'] {
    max-width: 26px!important;
  }

  [class*='workTime0'] {
    max-width: 29px!important;
  }

  [class*='workTime1'] {
    max-width: 29px!important;
  }

  [class*='workTime2'] {
    max-width: 29px!important;
  }

  [class*='workTime3'] {
    max-width: 22px!important;
  }

  [class*='diet0'] {
    max-width: 26.78px!important;
  }

  [class*='diet1'] {
    max-width: 27px!important;
  }

  [class*='diet2'] {
    max-width: 25.92px!important;
  }

  [class*='diet3'] {
    max-width: 28.74px!important;
  }

  [class*='ailments0'] {
    max-width: 27px!important;
  }

  [class*='ailments1'] {
    max-width: 24px!important;
  }

  [class*='ailments2'] {
    max-width: 29px!important;
  }

  [class*='ailments3'] {
    max-width: 26px!important;
  }

  [class*='typicalDay0'] {
    max-width: 24px!important;
  }

  [class*='typicalDay1'] {
    max-width: 34px!important;
  }

  [class*='typicalDay2'] {
    max-width: 25.87px!important;
  }

  [class*='typicalDay3'] {
    max-width: 28px!important;
  }


  

// sleep block
  [class*='sleep0'] {
    max-width: 28px!important;
  }
  [class*='sleep1'] {
    max-width: 28px!important;
  }
  [class*='sleep2'] {
    max-width: 28px!important;
  }

  // helps block
  [class*="helps0"] {
    max-width: 29px !important;
  }

  [class*="helps1"] {
    max-width: 30px !important;
  }

  [class*="helps2"] {
    max-width: 26px !important;
  }

  [class*="helps3"] {
    max-width: 30px !important;
  }

  [class*="helps4"] {
    max-width: 27px !important;
  }

  [class*="helps5"] {
    max-width: 31px !important;
  }

}