@import "./../../../assets/styles/base.scss";

.stepContentWrapper {
  height: 100%;
  overflow: auto;
  padding: 30px 0 100px 0;
  max-width: $maxDeviceWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 26px;
  line-height: 32px;
  color: #030303;
  letter-spacing: 0.41px;
  text-align: center;
  font-weight: bold;
  max-width: 335px;
}

.contentWrapper {
  margin-top: 29px;
  padding: 0 20px;
}

.contentItem {
  margin-top: 10px;
  height: 97px;
  min-height: 97px;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  overflow: hidden;
}

.contentItemImage {
  width: 80px;
  min-width: 80px;
  height: 100%;
}

.contentItemText {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-top: 1px solid #F8F8F8;
  border-right: 1px solid #F8F8F8;
  border-bottom: 1px solid #F8F8F8;
  border-radius: 0 8px 8px 0;
  padding: 12px 10px 10px 8px;
}

.contentItemTitle {
  font-weight: 700;
  font-size: 16px;
  color: #030303;
}

.contentItemDescription {
  margin-top: 8px;
  font-size: 12px;
  line-height: 17px;
  color: #808080;
}

.contentItem:nth-child(1) {
  margin-top: 0;
}

.workoutsButtonWrapper {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top: 0.5px solid #CACACC;
  z-index: 2;
    
  [class*="buttonFill"] {
    border-radius: 100px;
  }

}