@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Regular.eot');
    src: url('SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('SFProDisplay-Regular.woff2') format('woff2'),
        url('SFProDisplay-Regular.woff') format('woff'),
        url('SFProDisplay-Regular.ttf') format('truetype'),
        url('SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Light.eot');
    src: url('SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
        url('SFProDisplay-Light.woff2') format('woff2'),
        url('SFProDisplay-Light.woff') format('woff'),
        url('SFProDisplay-Light.ttf') format('truetype'),
        url('SFProDisplay-Light.svg#SFProDisplay-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Bold.eot');
    src: url('Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Bold.woff2') format('woff2'),
        url('Roboto-Bold.woff') format('woff'),
        url('Roboto-Bold.ttf') format('truetype'),
        url('Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Regular.eot');
    src: url('Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Regular.woff2') format('woff2'),
        url('Roboto-Regular.woff') format('woff'),
        url('Roboto-Regular.ttf') format('truetype'),
        url('Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Medium.eot');
    src: url('Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Medium.woff2') format('woff2'),
        url('Roboto-Medium.woff') format('woff'),
        url('Roboto-Medium.ttf') format('truetype'),
        url('Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

