@import "./../../../assets/styles/base.scss";

.stepContentWrapper {
  height: 100%;
  overflow: auto;
  padding: 34px 20px 100px 20px;
  max-width: $maxDeviceWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(./img/bg.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.title {
  font-size: 26px;
  line-height: 32px;
  color: #000000;
  letter-spacing: 0.41px;
  text-align: center;
  font-weight: bold;
  max-width: 335px;
  margin-bottom: 16px;
}

.logo {
  min-width: 80px;
  min-height: 80px;
  width: 80px;
  object-fit: cover;
  margin-bottom: 20px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 17px;
    line-height: 22px;
    color: #000000;
  }

  p + p {
    margin-top: 22px;
  }
}

.successStoryResultButtonWrapper {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 20px 20px 20px;
  border-top: 0.5px solid #808080;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
    
  [class*="buttonFill"] {
    border-radius: 100px;
  }

}

.successStoryResultButtonFill {
  cursor: pointer;
  padding: 0px 24px;
  border: 0px;
  position: relative;
  z-index: 1;
  text-align: left;
  max-width: $maxDeviceWidth;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  background: linear-gradient(270deg, rgba(255, 102, 140, 0.98) 0%, rgba(245, 29, 71, 0.98) 100%);
  box-shadow: 0px 5px 20px rgba(238, 51, 75, 0.4);
  border-radius: 100px;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.0352px;
  @include transition(opacity $animateElSeconds);
  font-weight: 500;
  color: #ffffff;
  height: 50px;

  &:disabled{
    opacity: 0.5;
  }
}