@import "./../../../assets/styles/base.scss";

  .texts {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .description {
    margin-top: 5px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: #808080;
    // color: inherit;
    

    .radioCheckButtonChecked .description {
      color: #fff;
    }
      
  }